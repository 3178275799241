.local_embed {
  display: block;
  max-width: 640px;
  margin: auto;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 0.8em;
}

div[dir=ltr][class=lang] .local_embed{
  border-left: 0.01em solid #eee;
}

div[dir=rtl][class=lang] .local_embed {
  border-right: 0.01em solid #eee;
}

.local_embed section {
  background: #f9f9f9;
  margin: 0.5em;
  border-bottom: none;
}

.local_embed .description {
  display: block;

  margin-bottom: 0.5em;
  font-size: 0.8em;
  color: #aaa;
  text-align:center;
}
