.respond {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5em;
  background-color: #ffffff;  
  padding: 0.5em;
  cursor: pointer;
}

div[dir=ltr][class=lang] .respond {  
  border-left: 5px solid #000;
}

div[dir=rtl][class=lang] .respond {
  border-right: 5px solid #000;
}

.respond .response {
  margin-left: 1em;
  margin-right: 1em;
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff;
  padding: 5px;
}

.respond .response:last-child {
  background-color: transparent;
  padding: 0;
}

.respond .response:hover {
  border-bottom: 0.01em solid #000; 
}
