.main_article {
  display: block;
  margin-bottom: 1em;
  overflow: auto;
}

article[dir=rtl] {
  font-size:1.1em;
}

.article {
  font-family: 'droid_arabic_kufi', 'roboto', 'serif' !important;
}
 
div[dir=rtl][class=lang] article {
  font-family: 'droid_arabic_kufi', 'roboto', 'serif' !important;
}
  

div[dir=rtl][class=lang] article * {
  text-align: right;
}

.article > * {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
}

.article > span, .article h2, .article ul, .article ol {
  margin-left: auto;
  margin-right: auto;
}

@media (pointer: coarse) {
  .article > span, .article h2, .article ul, .article ol {
    max-width: 90vw;
  }
}

@media (pointer: fine) {
  .article > span, .article h2, .article ul, .article ol {
    max-width: none;
  }
}

.article li {
  margin-left: 2em;
  margin-right: 2em;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.article blockquote  {
  color: #999;
  font-style: italic;
  padding-left: 10vw;
  padding-right: 10vw;
  margin-left: auto;
  margin-right: auto;
  max-width: 640px
}

.article .mention {
  color: #999999;
  background-color: #dceddd;
}

.article .link {
  color: #999999;
  text-decoration: underline;
}

.article .superscript {
  font-size: 0.6em;
  vertical-align: super;
  margin-left: 0.25em;
  margin-right: 0.25em;
}

.icon {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 2.5em;
}

.header {
  color: teal;
  border-top: 1px solid teal;
  border-bottom: 1px solid teal;
  margin-top: 10px;
  display: flex;
  align-content: center;
  flex-direction: row;
  font-size: 24px;
  font-weight: bold;  
}

.article picture {
  margin: 30px auto;
  max-width: 564px;
  height: auto;
}

.article:first-child {
  margin-top: 0;
}
