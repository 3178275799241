
.meta_pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background: #aeaeae;
}

.meta_holder {
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: .5em;
  font-size: 1em;
}

.meta_info_holder {
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  margin-left: .5em;
}

.meta_time {
  font-size: .8em
}

.meta_holder div span {
  color: #fff !important;
}

.meta_holder a {
  font-size: 1em;
}
