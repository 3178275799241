.event_date {
    padding: 5px 5px 5px 10px;
    background-color: #fff;
    margin-top: 0.25em;
}

div[dir=ltr][class=lang] .event_date {
    padding-left: 10px;
    border-left: 3px solid #000;
}

div[dir=rtl][class=lang] .event_date  {
    padding-right: 10px;
    border-right: 3px solid #000;
}

.event_date h3 {
    display: inline-block;
    margin-bottom: 0;
}

.event_date h3:first-child {
    margin-left: 0;
}

div[dir=ltr][class=lang] .event_date h3:last-child {  
    border-left: 1px solid #000;
    padding-left: 1em;
    margin-left: 1em;
}
  
div[dir=rtl][class=lang] .event_date h3:last-child {
    border-right: 1px solid #000;
    padding-right: 1em;
    margin-right: 1em;
}
  
.event_date h3 span {
    text-transform: uppercase;
}

.event_date div {
    margin: 8px 0;
}

.event_date p {
  font-size: 16px;
  line-height: 22px;    
  display: inline-block;
  color: #000;
}

div[dir=ltr][class=lang] .event_date p:first-child {
    margin-right: 1em;
}

div[dir=rtl][class=lang] .event_date p:first-child {
    margin-left: 1em;
}

div[dir=ltr][class=lang] .event_date p:last-child {
    border-left: 1px solid;
    padding-left: 1em;
}

div[dir=rtl][class=lang] .event_date p:last-child {
    border-right: 1px solid;
    padding-right: 1em;
}

.event_date p span {    
    text-transform: uppercase;
}

@media only screen and (max-width: 425px) {
    div[dir=rtl][class=lang] .event_date h3:last-child {
        border-right: 0;
        margin-right: 0;
    }

    div[dir=ltr][class=lang] .event_date h3:last-child {
        border-left: 0;
        margin-left: 0;
    }
}