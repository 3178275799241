
.embed_loading {
  display: block;
  padding-top: 180px;
  padding-bottom: 180px;
  color: #ddd;
  border: 0.01em solid #ddd;
  margin: auto;
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
}
